import {useContext} from "react";
import PostScrapContext, {postScrapContextRef} from "context/PostScrapContext";
import {Axios} from "api";
import UserContext from "context/AuthContext";
import {redirectToLogin} from "common/redirect";
import {useRouter} from "next/router";
import * as Sentry from "@sentry/nextjs";
import {toast} from "react-toastify";
import ToastContent from "components/ToastContent";
import {rewardsRoute} from "common/const";
import {showRewardToast} from "common/utils";

let requestAfterTimeout;

export default function PostScrapButton(props) {
    const {postId, renderScrapped, renderUnscrapped, height, onScrap, onUnscrap} = props;
    const ScrappedComponent = renderScrapped;
    const UnscrappedComponent = renderUnscrapped;
    const user = useContext(UserContext).user;

    const router = useRouter();

    const scrapContext = useContext(PostScrapContext);

    const sendRequest = async (isScrapped) => {
        // consider contextRef.current is not updated due to the component unmount
        // const isScrapped = scrapContextRef.current.isScrapped(postId);
        const data = {'post_id': postId, 'is_scrapped': isScrapped};
        // console.log(data);
        try {
            postScrapContextRef.current.setIsLoading(postId, true);
            const res = isScrapped
                ? await Axios.post(`v1/palette/scraps/`, data)
                : await Axios.delete(`v1/palette/scraps/`, {params: data});
            if (res.status < 400) {
                if (res.data.given_reward) {
                    showRewardToast(router, res.data.given_reward.key, res.data.given_reward.point);
                }
            } else {
                // alert('스크랩 설정에 실패했습니다. 잠시 후 다시 시도해주세요.');
            }
        } catch(e) {
            // alert('스크랩 설정에 실패했습니다. 잠시 후 다시 시도해주세요.');
            Sentry.captureException(e);
        } finally {
            postScrapContextRef.current.setIsLoading(postId, false);
        }
    }

    const sendRequestIfNotLoading = async (isScrapped) => {
        if (!postScrapContextRef.current.isLoading(postId)) {
            sendRequest(isScrapped);
            requestAfterTimeout = null;
        }
        else {
            requestAfterTimeout = setTimeout(() => {
                sendRequestIfNotLoading(isScrapped);
            }, 200);
        }
    }

    const setRequest = (isScrapped) => {
        if (requestAfterTimeout) {
            clearTimeout(requestAfterTimeout);
        }
        requestAfterTimeout = setTimeout(() => {
            sendRequestIfNotLoading(isScrapped);
        }, 200);
    }


    const scrap = () => {
        scrapContext.addPostIds([postId]);
        setRequest(true);
        typeof mixpanel !== 'undefined' && mixpanel.track('PostScrap', {
            postId,
        });
        if (onScrap !== undefined) {
            onScrap();
        }
    };

    const unscrap = () => {
        scrapContext.removePostId(postId);
        setRequest(false);
        typeof mixpanel !== 'undefined' && mixpanel.track('PostUnscrap', {
            postId,
        });
        if (onUnscrap !== undefined) {
            onUnscrap();
        }
    }

    const onClick = scrapContext.isScrapped(postId) ? unscrap : scrap;

    return (
        <div
            onClick={() => {
                if (user) {
                    onClick();
                } else {
                    redirectToLogin(router, true);
                }
            }}
            style={{lineHeight: 0}}
        >
            {
                scrapContext.isScrapped(postId)
                    ? <ScrappedComponent />
                    : <UnscrappedComponent />
            }
        </div>
    )
}
