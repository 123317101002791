import {useContext, useEffect, useRef, useState} from "react";
import {getPixelValueFromString} from "common/utils";
import utilStyles from "styles/utils.module.scss";
import styles from "components/media/video.module.scss";
import ReactPlayer from "react-player";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MediaContext from "context/MediaContext";
import PlayIcon from "images/icons/play.svg";
import PauseIcon from "images/icons/pause.svg";
import Image from "next/image";
import {useInView} from "react-intersection-observer";
import {STATUS_CONFIRM_PURCHASE} from "pages/mypage/orders";
import {useRouter} from "next/router";


const controlIcon = (button) => {
    return (
        <div className={styles.iconWrapper}>
            {
                button === 'play'
                    ? <PlayIcon/>
                    : <PauseIcon/>
            }
        </div>
    )
}

export function VideoThumbnail(props) {
    const {item, play} = props;
    // const height = props.height || `177.78%`;
    // const heightNum = parseInt(height.split('%')[0])
    // const overflow = heightNum > 100 ? (heightNum - 100) / 2 : 0;
    return (
        <>
            <div className={styles.wrapper} style={{paddingBottom: '100%',}}>
                <div className={styles.player}>
                    {
                        item.thumbnail_url && !play
                            ?
                            <Image unoptimized src={item.thumbnail_url} alt={'media'} layout="fill"
                                   objectFit="cover"
                            />
                            :
                            <ReactPlayer
                                autoPlay={play}
                                className={styles.video}
                                url={item.url} width={'100%'} height={`100%`}
                            />
                    }
                </div>
            </div>
        </>
    )
}


export default function VideoPlayer(props) {
    const {item, autoplay, isActive, noControl} = props;
    const height = props.height || `177.78%`;
    const router = useRouter();
    const onContentView = props.onContentView || function (id) {};

    const [played, setPlayed] = useState(0.0);
    const [duration, setDuration] = useState(0);
    const [seeking, setSeeking] = useState(false);
    const [showControl, setShowControl] = useState(true);
    const [showPlayButton, setShowPlayButton] = useState('');
    const [volume, setVolume] = useState(1);
    const [muted, setMuted] = useState(true);
    const [initialized, setInitialized] = useState(false);

    const videoRef = useRef(null);
    // const containerRef = useRef(null);
    const timerRef = useRef(null);
    const iconTimerRef = useRef(null);
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);

    const [ref, inView, entry] = useInView({
        threshold: 0,
        triggerOnce: false,
        rootMargin: '-49% 0px -49% 0px',
        // rootMargin: '0px 0px 0px 0px',
        fallbackInView: true,
    });


    const [nearRef, nearInView, nearEntry] = useInView({
        threshold: 0,
        triggerOnce: false,
        rootMargin: '50% 0px 50% 0px',
        // rootMargin: '0px 0px 0px 0px',
        fallbackInView: true,
    });

    const mediaContext = useContext(MediaContext);

    useEffect(() => {
        if (!isActive) {
            togglePlay(false);
            setSeeking(false);
        }
    }, [isActive]);

    useEffect(() => {
        if (!initialized && nearInView) {
            setInitialized(true);
        }
    }, [nearInView]);

    useEffect(() => {
        // if (item.mute) {
        //     setMuted(true);
        // }
        if (autoplay) {
            // if (isMobile) {
            //     setMuted(true);
            // }
            setTimeout(() => {
                togglePlay(true);
            }, 200);
        }
    }, [item]);

    useEffect(() => {
        if (isActive) {
            togglePlay(inView, true);
            if (inView) {
                onContentView(item);
            }
        }
    }, [inView, isActive]);

    const setIconTimer = () => {
        if (iconTimerRef.current) {
            clearTimeout(iconTimerRef.current);
        }
        iconTimerRef.current = setTimeout(() => {
            setShowPlayButton('');
            clearTimeout(iconTimerRef.current);
        }, 500);
    }

    const togglePlay = (play, noButton = false) => {
        if (play) {
            mediaContext.play(item.id);
            if (!noButton) {
                setShowPlayButton('play');
                setIconTimer();
            }
        } else {
            mediaContext.pause(item.id);
            if (!noButton) {
                setShowPlayButton('pause');
                setIconTimer();
            }
        }
    }

    const setHideTimer = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            setShowControl(false);
            clearTimeout(timerRef.current);
        }, 5000);
    }

    const onClick = () => {
        if (showControl) {
            togglePlay(!mediaContext.isPlaying(item.id));
        } else {
            if (!mediaContext.isPlaying(item.id)) {
                togglePlay(true);
            }
            setShowControl(true);
        }
        if (isMobile) {
            setHideTimer();
        }
    }

    const onMouseEnter = () => {
        setShowControl(true);
    }

    const onMouseExit = () => {
        setShowControl(false);
    }

    const onDuration = (d) => {
        setDuration(d);
    }

    const handleProgress = (state) => {
        if (!seeking) {
            setPlayed(state.played);

        }
    }

    const handleSeekMouseDown = e => {
        e.stopPropagation();
        setSeeking(true);
    }

    useEffect(() => {
        if (inputRef.current) {
            const min = inputRef.current.min;
            const max = inputRef.current.max;
            const val = inputRef.current.value;
            document.documentElement.style.setProperty('--input-range-slider', (val - min) * 100 / (max - min) + '%');
        }
    }, [played]);

    const handleSeekChange = e => {
        e.stopPropagation();
        e.preventDefault();
        setPlayed(parseFloat(e.target.value));
    }

    const handleSeekMouseUp = e => {
        setSeeking(false);
        e.stopPropagation();
        if (videoRef.current) {
            videoRef.current.seekTo(parseFloat(e.target.value));
        }
    }

    const onMuteButtonClick = (e) => {
        if (isMobile) {
            setMuted(false);
        }
        else {
            mediaContext.unmute();
        }
        e.preventDefault();
        e.stopPropagation();

        // router.replace(
        //     {
        //         query: Object.assign({}, router.query, {muted: 0}),
        //     },
        //     undefined,
        //     {shallow: true}
        // );
    }

    const isMuted = () => {
        return mediaContext.isMute() || item.mute;
        // if (isMobile) {
        //     return muted || item.mute;
        // } else {
        //     return mediaContext.isMute() || item.mute;
        // }
    }

    const inputRef = useRef(null);

    return (
        <>
            <div className={styles.wrapper} style={{paddingBottom: height}} ref={nearRef}>
                <div className={styles.player} onClick={onClick} ref={ref} onMouseEnter={onMouseEnter}
                     onMouseLeave={onMouseExit}>
                    {
                        initialized
                            ?
                            <>
                                {
                                    showControl && !noControl &&
                                    <div className={styles.overlay}>
                                        {/*<Slider aria-label="Volume" value={played} onChange={seek} min={0} max={1} />*/}
                                        {showPlayButton && controlIcon(showPlayButton)}
                                        <input
                                            className={styles.slider}
                                            type='range' min={0} max={0.999999} step='any'
                                            ref={inputRef}
                                            value={played}
                                            onMouseDown={handleSeekMouseDown}
                                            onChange={handleSeekChange}
                                            onMouseUp={handleSeekMouseUp}
                                            onClick={e => {
                                                e.stopPropagation();
                                            }}
                                            // onTouchStart={handleSeekMouseDown}
                                            // onTouchEnd={handleSeekMouseUp}
                                            // onTouchMove={e => {
                                            //     e.stopPropagation();
                                            //     e.preventDefault();
                                            // }}
                                            onTouchStartCapture={handleSeekMouseDown}
                                            onTouchEndCapture={handleSeekMouseUp}
                                            onTouchMoveCapture={e => {
                                                e.stopPropagation();
                                                // e.preventDefault();
                                            }}
                                        />
                                        {
                                            isMuted() && !item.mute &&
                                            <div className={styles.muteButton} onClick={onMuteButtonClick}>
                                                <span>음소거 해제</span>
                                            </div>
                                        }
                                    </div>
                                }
                                <ReactPlayer
                                    className={styles.video}
                                    config={{file: {attributes: {poster: item.thumbnail_url}}}}
                                    ref={videoRef}
                                    url={item.url} width={'100%'} height={`100%`} loop
                                    // light={item.thumbnail_url ? (!mediaContext.isPlaying(item.id) && item.thumbnail_url) : !mediaContext.isPlaying(item.id) && !nearInView}
                                    light={!nearInView ? item.thumbnail_url : false}
                                    // file={item.thumbnail_url ? { attributes: { poster: item.thumbnail_url } } : {}}
                                    // controls
                                    playsinline
                                    playing={mediaContext.isPlaying(item.id)}
                                    onProgress={handleProgress}
                                    onDuration={onDuration}
                                    volume={1}
                                    // muted={true}
                                    // muted={mediaContext.isMute() || !mediaContext.isPlaying(item.id)}
                                    muted={isMuted()}
                                    // playing={true}
                                    // muted={isMobile ? true : muted}
                                />
                            </>
                            :
                            <div></div>
                    }
                </div>
            </div>
        </>
    )
}
